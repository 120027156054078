<template>
  <canvas ref="barcodeCanvas" />
</template>

<script>
  import bwipjs from 'bwip-js'

  export default {
    props: {
      data: {
        type: String,
        required: true,
      },
      size: {
        type: Number,
        default: 5, // Adjust for barcode size
      },
      type: {
        type: String,
        default: 'qrcode', // qrcode //azteccode //pdf417
      },
    },
    watch: {
      data () {
        this.generateBarcode()
      },
    },
    mounted () {
      this.generateBarcode()
    },
    methods: {
      generateBarcode () {
        if (!this.data) return

        try {
          bwipjs.toCanvas(this.$refs.barcodeCanvas, {
            bcid: this.type,
            text: this.data,
            scale: this.size, // Size scaling
            includetext: false, // Hide human-readable text
          })
        } catch (err) {
          console.error('Aztec Code generation error:', err)
        }
      },
    },
  }
</script>
